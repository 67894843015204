/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Interface
import { UspProps } from 'components/flex/Usp/UspShell'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

const Usp = styled.div`
  background-color: ${({ theme }) => theme.color.light};

  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    margin-bottom: 1.5rem;
  }
`

const UspWrapper = styled.div`
  height: 100%;

  @media (max-width: 991px) {
    height: auto;
  }

  @media (max-width: 576px) {
    height: fit-content;
  }
`

const UspBlanco: React.FC<UspProps> = ({ fields }) => (
  <section className="mb-5 pb-lg-5">
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-lg-11">
          <div className="row h-100">
            {fields.usps?.map((u, index) => (
              <UspWrapper className="col-sm-6 col-md-4 mb-4" key={index}>
                <Usp className="px-4 d-flex flex-column justify-content-between pt-4 pb-5 h-100">
                  <div>
                    <h2>{u?.usp}</h2>
                    <ParseContent content={u?.uspdescription} />
                  </div>
                  <div className="d-flex pt-3 justify-content-center">
                    <ButtonPrimary to={u?.link?.url || '/'}>
                      {u?.link?.title}
                    </ButtonPrimary>
                  </div>
                </Usp>
              </UspWrapper>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default UspBlanco
