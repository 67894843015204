/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import AnchorPrimary from 'components/elements/Anchors/AnchorPrimary'

// Interface
import { UspProps } from 'components/flex/Usp/UspShell'

// Images
import Chevron from 'img/chevronusp.inline.svg'
import Plaatjie from '@ubo/plaatjie'

const Usp = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.light};
  font-weight: ${({ theme }) => theme.font.weight.bold};

  & p {
    font-size: 27px;

    @media (min-width: 992px) {
      padding-top: 0.66rem;
      padding-bottom: 0.66rem;
    }
    @media (max-width: 991px) {
      padding-top: 0.9rem;
      padding-bottom: 0.9rem;
    }
  }
`

const Content = styled(ParseContent)`
  z-index: 2;
  background-color: rgba(229, 229, 229, 0.9);
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 > strong {
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
  }

  & p {
    padding-right: 1rem;
  }
`

const ChevronWrapper = styled.div`
  & svg {
    height: 88px !important;
    width: 100px !important;
    @media (max-width: 991px) {
      height: 97px !important;
    }
  }

  & .first {
    top: -2px;
    left: -53px;
    & path {
      fill: ${({ theme }) => theme.color.grey};
    }
  }

  & .second {
    top: -2px;
    left: -33px;
    & path {
      fill: ${({ theme }) => theme.color.grey};
    }
  }
  & .third {
    top: -2px;
    right: -28px;

    & path {
      fill: ${({ theme }) => theme.color.grey};
    }
  }

  & .fourth {
    top: -2px;
    right: -53.4px;

    & path {
      fill: ${({ theme }) => theme.color.grey};
    }
  }

  & .fifth {
    top: -2px;
    right: -43.4px;
  }
`

const Image = styled(Plaatjie)`
  @media (min-width: 1200px) {
    width: 130%;
    max-height: 480px;
  }
`

const UspDefault: React.FC<UspProps> = ({ fields }) => (
  <section className="position-relative mb-5 pb-lg-5">
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-xl-4 mb-5 mb-sm-0 mt-lg-5">
              {fields.usps?.map((u, index) => (
                <Usp
                  key={index}
                  className="position-relative text-center mb-4 mb-lg-5 me-5 px-4 py-2 mt-lg-5"
                >
                  <ChevronWrapper>
                    <Chevron className="position-absolute first" />
                    <Chevron className="position-absolute second" />
                  </ChevronWrapper>
                  <p className="mb-0">{u?.usp}</p>
                  <ChevronWrapper>
                    <Chevron className="position-absolute third" />
                    <Chevron className="position-absolute fourth" />
                    <Chevron className="position-absolute fifth" />
                  </ChevronWrapper>
                </Usp>
              ))}
            </div>
            <div className="col-lg-6 col-xl-5">
              <div className="px-lg-5">
                <Content
                  content={fields.description}
                  className="py-4 w-100 h-100"
                />
                <AnchorPrimary href={fields.link?.url || '#'}>
                  <span className="font-weight-bold">{fields.link?.title}</span>
                </AnchorPrimary>
              </div>
            </div>
            <div className="col-sm-6 col-xl-3">
              <Image
                image={fields.image}
                alt=""
                className="h-100 mt-5 mt-xl-0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default UspDefault
