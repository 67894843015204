/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Interface
import { UspProps } from 'components/flex/Usp/UspShell'

const Usp = styled.div`
  background-color: ${({ theme }) => theme.color.light};

  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    margin-bottom: 1.5rem;
  }
`

const UspImage: React.FC<UspProps> = ({ fields }) => (
  <section className="mb-5 pb-lg-5">
    <div className="container py-lg-5">
      <div className="row justify-content-center">
        <div className="col-lg-11">
          <div className="row">
            {fields.usps?.map((u, index) => (
              <div className="col-md-6 col-lg-4 h-100 mb-4" key={index}>
                <Plaatjie image={u?.image} alt="" />
                <Usp className="px-4 pt-4 pb-5">
                  <h2>{u?.usp}</h2>
                  <ParseContent content={u?.uspdescription} />
                </Usp>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default UspImage
