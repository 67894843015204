/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled, { css } from 'styled-components'
import { useMedia } from 'use-media'

// Elements
import ParseContent from 'components/shared/ParseContent'
import { motion } from 'framer-motion'

// Interface
import { UspProps } from 'components/flex/Usp/UspShell'

const Usp = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.light};
  font-weight: ${({ theme }) => theme.font.weight.bold};

  & span {
    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 50px !important;
    }
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  @media (min-width: 767px) {
    font-size: 30px;
  }
`

const TriangleCSS = css`
  top: 0;
  width: 0;
  height: 0;

  @media (min-width: 768px) {
    border-top: 34px solid transparent;
    border-bottom: 34px solid transparent;
  }
  @media (max-width: 767px) {
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }
`

const TriangleBackground = styled.div`
  ${TriangleCSS}
  left: 0;
  @media (min-width: 768px) {
    border-left: 34px solid ${({ theme }) => theme.color.grey};
  }
  @media (max-width: 767px) {
    border-left: 20px solid ${({ theme }) => theme.color.grey};
  }
`

const TrianglePrimary = styled.div`
  ${TriangleCSS}
  @media (min-width: 768px) {
    border-left: 34px solid ${({ theme }) => theme.color.primary};
    right: -34px;
  }
  @media (max-width: 767px) {
    border-left: 20px solid ${({ theme }) => theme.color.primary};
    right: -20px;
  }
`

const UspChevron = styled.div`
  top: 5px;
  border-style: solid;
  border-color: ${({ theme }) => theme.color.secondary};
  content: '';
  display: inline-block;
  transform: rotate(45deg);
  vertical-align: top;
  @media (min-width: 768px) {
    width: 59px;
    height: 59px;
    border-width: 13px 13px 0 0;
  }
  @media (max-width: 767px) {
    width: 32px;
    height: 32px;
    right: -32px;
    border-width: 8px 8px 0 0;
  }
`
const UspPerUsp: React.FC<UspProps> = ({ fields }) => {
  const isDesktop = useMedia({ minWidth: 768 })

  const bounceTransition = {
    x: {
      yoyo: Infinity,
    },
  }

  return (
    <section className="mb-5 pb-lg-5">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            {fields.usps?.map((u, index) => (
              <div
                className="row justify-content-between mb-5 pb-lg-4"
                key={index}
              >
                <div className="col-sm-4 mb-3 mb-sm-0">
                  <Usp className="position-relative text-center mb-4 me-5 px-4 py-2">
                    <TriangleBackground className="position-absolute" />
                    <span>{u?.usp}</span>
                    <TrianglePrimary className="position-absolute" />
                    <motion.div
                      animate={
                        isDesktop
                          ? { x: [140, 130], y: -60.5 }
                          : { x: [20, 30], y: -33 }
                      }
                      transition={bounceTransition}
                    >
                      <UspChevron className="position-absolute" />
                    </motion.div>
                  </Usp>
                </div>
                <div className="col-sm-7">
                  <ParseContent content={u?.uspdescription} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default UspPerUsp
